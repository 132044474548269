import React from 'react';
import { Form } from 'antd';

const Global = props => {
    return (
        <React.Fragment>
            <div className="headerfull">
                <div className="logoheader">
                </div>
            </div>
            <div className="mainbody">
                <div className="title_top">
                    Для прохождения опроса нужно иметь приглашение
                </div>
            </div>
        </React.Fragment>
    )
};

export default Global;